import authService from './services/authService';
import jwt_decode from 'jwt-decode';
import UserService from './services/userService';

const newArray = [];

export const dateFilter = (dateString, transactions) => {
	const start = Date.parse(dateString[0]);
	const end = Date.parse(dateString[1]);

	return transactions.filter(
		(transaction) =>
			Date.parse(transaction.created) >= start &&
			Date.parse(transaction.created) <= end,
	);
};

export const searchFilter = async (searchTerm, transactions) => {
	return transactions.filter((transaction) =>
		transaction.merchantRef.includes(searchTerm),
	);
};

export const statusFilter = (status, transactions) => {
	newArray.push(transactions);
	newArray.filter((transaction) => transaction.status == status);
};

export const checkToken = () => {
	const token = authService.getCurrentUser();
	try {
		const decoded = jwt_decode(token);
		if (decoded.exp && decoded.exp > Date.now() / 1000) {
			return this.setState({ token });
		}

		if (decoded.exp < Date.now() / 1000) {
			this.props.history.push({
				pathname: '/',
				errorMessage: 'Kindly sign in again.',
			});
		}
	} catch (error) {
		console.log(error);
		return this.props.history.push({
			pathname: '/',
			errorMessage: 'kindly signup or login',
		});
	}
};

//Return true is email entered is valid else return false
export const MatchEmail = (email) => {
	return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
		email,
	);
};

// export const getSingleTransaction = async (transactionId) => {
// 	const transaction = await UserService.getTransaction(transactionId);
// 	console.log(transaction);
// };
