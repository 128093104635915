import React, { Component } from 'react';
import AuthService from '../../../services/authService';
import jwt_decode from 'jwt-decode';
import './Settings.css';
import { Button, Form, Input, Select, Spin } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { Spinner } from '../../elements/Spinner/Spinner';
import UserService from '../../../services/userService';

const layout = {
	labelCol: {
		span: 8,
	},
	wrapperCol: {
		span: 16,
	},
};
const tailLayout = {
	wrapperCol: {
		offset: 8,
		span: 16,
	},
};

class Settings extends Component {
	state = {
		isError: true,
		publickey: '',
		secretkey: '',
		edit: true,
		errorInfo: false,
		loading: false,
	};

	onSave = async () => {
		const { publickey, secretkey } = this.state;
		// if (!publickey || !secretkey) {
		// 	return console.log('fill all the required fields');
		// }
		this.setState({ edit: true });
		const newKey = {
			publickey,
			secretkey,
		};
		await UserService.updateProfile(newKey);
		window.location.reload();
	};

	onChange = (input) => {
		const { name, value } = input.target;
		this.setState({ [name]: value });
	};

	onEdit = (event) => {
		this.setState({ edit: false });
	};

	async componentDidMount() {
		this.setState({ loading: true });
		const token = AuthService.getCurrentUser();
		const decoded = jwt_decode(token);
		if (decoded.exp < Date.now() / 1000) {
			this.props.history.push({
				pathname: '/',
				errorMessage: 'Kindly sign in again.',
			});
		}

		const merchant = await UserService.getMerchant();

		this.setState({
			publickey: merchant.data.publicKey,
			secretkey: merchant.data.secretKey,
			isError: merchant.data.isError,
		});
		this.setState({ loading: false });
	}

	render() {
		return (
			<div>
				{this.state.loading ? (
					<Spin />
				) : (
					<Form
						// {...layout}
						style={{ margin: '10% 30% ', width: 'auto' }}
						name='basic'
						initialValues={{
							remember: true,
						}}>
						<Form.Item
							label='Public Key'
							name='publickey'
							rules={[
								{
									required: true,
									message:
										'Please enter the name of the account holder!',
								},
							]}>
							<Input
								name='publickey'
								onChange={this.onChange}
								defaultValue={this.state.publickey}
								readOnly={this.state.edit}
							/>
						</Form.Item>

						<Form.Item
							label='Secret Key'
							name='secretkey'
							rules={[
								{
									required: true,
									message: 'Please add contact number!',
								},
							]}>
							<Input
								name='secretkey'
								type='text'
								onChange={this.onChange}
								readOnly={this.state.edit}
								defaultValue={this.state.secretkey}
							/>
						</Form.Item>

						<Form.Item
							style={{
								width: 'min-content',
								marginLeft: '14%',
							}}>
							{this.state.edit ? (
								<Button
									type='primary'
									onClick={this.onEdit}
									style={{
										width: 'min-content',
										marginLeft: '100%',
									}}>
									Edit Details
								</Button>
							) : (
								<Button type='primary' onClick={this.onSave}>
									Save Details
								</Button>
							)}
						</Form.Item>
					</Form>
				)}
			</div>
		);
	}
}

export { Settings };
