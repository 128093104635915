import React, { useState, useEffect } from 'react';
import { Layout, message as messages } from 'antd';
import './Register.css';
import axios from 'axios';
import { API_URL } from '../../../config';
import { Spinner } from '../../elements/Spinner/Spinner';
import { Link, NavLink, useHistory } from 'react-router-dom';
import UserService from '../../../services/userService';
import authHeader from '../../../services/authHeader';

const { Header, Content, Footer } = Layout;

export const Register = () => {
	const history = useHistory();
	const [loading, setLoading] = useState(false);
	const [confirmpassword, setConfirmPassword] = useState('');
	const [passwordmatch, setPasswordMatch] = useState('');
	const [message, setMessage] = useState('');
	const [agreement, setAgreement] = useState('');
	const [checkMessage, setCheckMessage] = useState(false);
	const [countries, setCountries] = useState([]);
	const [
		emailVerificationEndpoint,
		setEmailVerificationEndpoint,
	] = 'https://gateway.broadpay.io/confirm';
	const [details, setDetails] = useState({
		name: '',
		email: '',
		siteurl: '',
		description: '',
		accountcurrency: '',
		password: '',
		countryCode: '',
		emailVerificationEndpoint: 'https://gateway.broadpay.io/confirm',
	});
	const currencies = ['ZMW', 'USD', 'GBP', 'ZAR'];

	useEffect(async () => {
		setLoading(true);
		const res = await UserService.getCountries();
		console.log(res);
		setCountries((values) => [...values, ...res.data.countries]);
		setLoading(false);
	}, []);

	const onSubmit = async (event) => {
		event.preventDefault();

		if (agreement) {
			messages.loading('Kindly be patient as we create your account.', 3);
			const newdetails = details;
			const result = await axios.post(`${API_URL}register`, details);
			console.log(result);
			if (result.data.isError === false) {
				setMessage(result.message);
				messages.success('Account created successfully', 3);
				localStorage.setItem('token', result.data.token);
				return history.push('/dashboard/overview');
			}
			return messages.error(
				'Failed to create account, please check that all details are correct and try again.',
				3
			);
		}
		setCheckMessage('Please accept the terms and conditions');
	};
	const oncheck = (event) => {
		const value = event.target.value;
		setAgreement(value);
	};
	const onchange = (event) => {
		const { name, value } = event.target;
		event.preventDefault();
		setDetails((values) => ({
			...values,
			[name]: value,
		}));
	};

	return (
		<Layout style={{ margin: 0 }}>
			<Header
				className='site-layout-background'
				style={{
					color: 'white',
					textAlign: 'center',
					fontSize: '20px',
					fontWeight: 'bolder',
				}}>
				<NavLink to='/' style={{ color: 'white' }}>
					Broadpay Gateway Portal
				</NavLink>
			</Header>
			<Layout>
				<Content
					className='content'
					style={{ height: '100vh', width: '100%' }}>
					<form style={{ margin: '5% 30% ' }}>
						<div className='container-fluid'>
							<div class='form-row'>
								<div class='form-group col-md-6'>
									<label for='name'>Business Name</label>
									<input
										onChange={onchange}
										type='text'
										name='name'
										class='form-control'
										id='business name'
										placeholder='Business Name'
										required
									/>
								</div>
								<div class='form-group col-md-6'>
									<label for='email'>Email Address</label>
									<input
										onChange={onchange}
										name='email'
										type='email'
										class='form-control'
										id='email-address'
										placeholder='eg, example@email.com'
										required
									/>
								</div>
							</div>
							<div class='form-row'>
								<div class='form-group col-md-6'>
									<label for='Business Description'>
										Business Description
									</label>
									<input
										onChange={onchange}
										type='text'
										name='description'
										class='form-control'
										id='business-description'
										placeholder='business description'
										required
									/>
								</div>
								<div class='form-group col-md-6'>
									<label for='siteUrl'>Website URL</label>
									<input
										onChange={onchange}
										type='text'
										name='siteUrl'
										class='form-control'
										id='siteUrl'
										placeholder='e.g, www.example.com'
									/>
								</div>
							</div>
							<div class='form-row'>
								<div class='form-group col-md-6'>
									<label for='countryCode'>Country</label>
									<select
										id='country'
										name='countryCode'
										class='form-control'
										required
										onChange={onchange}>
										{countries.map((country) => {
											return (
												<option value={country.code}>
													{country.name}
												</option>
											);
										})}

									</select>
								</div>

								<div class='form-group col-md-6'>
									<label for='accountCurrency'>
										Preferred Currency
									</label>
									<select
										id='currency'
										name='accountCurrency'
										class='form-control'
										required
										onChange={onchange}>
										<option selected></option>
										{currencies.map((currency) => (
											<option value={currency}>
												{currency}
											</option>
										))}
									</select>
								</div>
							</div>
							<div class='form-row'>
								<div class='form-group col-md-6'>
									<label for='password'>
										Create Password
									</label>
									<input
										onChange={onchange}
										type='password'
										name='password'
										class='form-control'
										id='inputpassword'
										required
									/>
								</div>
								<div class='form-group col-md-6'>
									<label for='confirmpassword'>
										Confirm Password
									</label>
									<input
										onChange={onchange}
										type='password'
										name='confirmpassword'
										class='form-control'
										id='inputconfirmpassword'
										required
									/>
									<small>
										{passwordmatch ? passwordmatch : null}
									</small>
								</div>
							</div>

							<div class='form-group'>
								<div class='form-check'>
									<input
										onClick={oncheck}
										class='form-check-input'
										type='checkbox'
										id='gridCheck'
									/>
									<small style={{ color: 'red' }}>
										{checkMessage}
									</small>
									<label
										class='form-check-label'
										for='gridCheck'
										style={{
											marginLeft: '2%',
											color: '#337ab7',
										}}>
										I agree to the terms and conditions
									</label>
								</div>
							</div>
							<div
								style={{
									width: '11%',
								}}>
								<button type='button' class='btn btn-link'>
									<Link
										style={{
											fontWeight: 'bold',
											color: '#3f86c9',
										}}
										to='/'
										class='badge-light'>
										{' '}
										Have an account? Sign in
									</Link>
								</button>
							</div>
							<button
								// type='submit'
								class='btn btn-primary'
								style={{
									marginLeft: '35%',
									width: '30%',
									marginBottom: '2%',
								}}
								onClick={onSubmit}>
								Sign up
							</button>
							{message ? (
								<small style={{ color: 'red' }}>
									{message}
								</small>
							) : (
								''
							)}
						</div>
					</form>
				</Content>
				<Footer
					style={{
						textAlign: 'center',
						backgroundColor: '#001529',
						color: 'white',
					}}>
					Copyright © Broadpay 2023
				</Footer>
			</Layout>
		</Layout>
	);
};
