import React, { useState, useEffect } from 'react';
import 'antd/dist/antd.css';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { Table, SearchTableInput } from 'ant-table-extensions';
import UserService from '../../../services/userService';
import Modal from 'antd/lib/modal/Modal';
import { SearchOutlined } from '@ant-design/icons';
import { Col, Divider, Drawer, Row } from 'antd';

export const TransactionsTable = ({ data, columns, loader, expColumns }) => {
	const [load, setLoad] = useState(false);
	const [customerDetails, setCustomerDetails] = useState([]);
	const [visible, setVisible] = useState(false);
	const [searchDataSource, setSearchDataSource] = useState(null);
	const [isSearching, setIsSearching] = useState(false);
	const [filterCustomer, setFilterCustomer] = useState({});

	useEffect(() => {
		const filteredCustomer = {};
		Object.keys(customerDetails).forEach((element) => {
			if (customerDetails[element] instanceof Object) {
				Object.keys(customerDetails[element]).map((el) => {
					filteredCustomer[`${el}`] = customerDetails[element][el];
				});

				delete customerDetails[element];
			}

			filteredCustomer[`${element}`] = customerDetails[element];
		});

		setFilterCustomer(filteredCustomer);
		console.log('the new object', filteredCustomer);
	}, [customerDetails]);

	const customExpandIcon = (props) => {
		const onclick = async (e) => {
			setLoad(true);
			const data = await UserService.getTransaction(props.record.id);
			const outerData = data.data;
			const details = { ...data.data.customer, ...outerData };

			setCustomerDetails(details);
			if (filterCustomer) {
				setVisible(true);
				setLoad(false);
			}
		};
		if (props.expanded) {
			return (
				<a
					style={{ color: 'black' }}
					onClick={(e) => {
						props.onExpand(props.record, e);
					}}>
					<KeyboardArrowUpIcon />
				</a>
			);
		} else {
			return (
				<a style={{ color: 'black' }} onClick={onclick}>
					<VisibilityIcon
						style={{
							color: 'blue',

							width: '20',
							height: '20',
						}}
					/>
				</a>
			);
		}
	};
	const change = (e) => {
		const { value } = e.target;
		if (!value || value == ' ') {
			return setIsSearching(false);
		}
		setIsSearching(true);
	};

	return (
		<div>
			<Drawer
				width={500}
				title='Detailed Transaction'
				placement='right'
				visible={visible}
				onClose={() => setVisible(false)}
				style={{
					marginLeft: '10%',
					marginTop: '50px',
					marginBottom: '50px',
				}}
				>
				{Object.keys(filterCustomer).map((el) => (
					<>
						<Row wrap={false} gutter={24}>
							<Col
								xs={{ span: 6, offset: 1 }}
								lg={{ span: 6, offset: 2 }}>
								{el}
							</Col>
							<Col
								xs={{ span: 11, offset: 1 }}
								lg={{ span: 20, offset: 2 }}>
								{filterCustomer[el]}
							</Col>
						</Row>
						<Divider />
					</>
				))}
			</Drawer>
			<SearchTableInput
				columns={columns}
				dataSource={data} // 🔴 Original dataSource
				setDataSource={setSearchDataSource} // 🔴 Newly created setSearchDataSource from useState hook
				inputProps={{
					placeholder: 'Search by any field',
					prefix: <SearchOutlined style={{ color: 'blue' }} />,
					onChangeCapture: change,
					style: {
						width: '20%',
						display: 'flex',
						float: 'right',
					},
				}}
			/>
			<Table
				size='small'
				bordered
				scroll={{ y: 600, x: 700 }}
				showHeader={true}
				sticky={true}
				selections={true}
				pagination={false}
				rowKey='id'
				dataSource={isSearching ? searchDataSource : data}
				loading={loader}
				expandIcon={(props) => customExpandIcon(props)}
				columns={columns}
				onSelect={onclick}
				expandable={{
					expandedRowRender: (records) => {
						console.log(customerDetails);
						return (
							<Modal
								title='Modal 1000px width'
								centered
								visible={visible}
								onOk={() => setVisible(false)}
								onCancel={() => setVisible(false)}
								width={1000}>
							</Modal>
						);
					},
				}}
				onFilter={(e) => console.log(e)}
				exportable
			/>
			,
		</div>
	);
};
