import jwt_decode from 'jwt-decode';

export default function authHeader() {
	const token = localStorage.getItem('token');
	// let decoded;
	// if(token) {
	// decoded = jwt_decode(token);
	// }
	if (token && token !== null) {
		const decoded = jwt_decode(token);
		if (decoded.exp && decoded.exp > Date.now() / 1000) {
			return { token: token };
		}
	} else {
		console.log(
			'there was an error on setting the header in the authheader service',
		);
		// return window.location.reload();
	}
}
