import React, { useState, useEffect } from 'react';
import AuthService from '../../../services/authService';
import { Spinner } from '../../elements/Spinner/Spinner';
import jwt_decode from 'jwt-decode';
import UserService from '../../../services/userService';
import { Form, Input, Button, Select, Switch, message } from 'antd';
import { v1 as uuidv1 } from 'uuid';
import './Payouts.css';

const Payouts = () => {
	const v1options = {
		node: [0x01, 0x23, 0x45, 0x67, 0x89, 0xab],
		clockseq: 0x1234,
		msecs: Date.now(),
		nsecs: 5678,
	};
	const currencies = ['ZMW', 'USD', 'GBP', 'ZAR'];
	const [transactionName, setTransactionName] = useState('');
	const [amount, setAmount] = useState(0);
	const [currency, setCurrency] = useState('');
	const [transactionReference, setTransactionReference] = useState('');
	const [customerFirstName, setCustomerFirstName] = useState('');
	const [customerLastName, setCustomerLastName] = useState('');
	const [customerEmail, setCustomerEmail] = useState('');
	const [customerPhone, setCustomerPhone] = useState(0);
	const [wallet, setWallet] = useState(0);
	const [chargeMe, setChargeMe] = useState(false);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		setTransactionReference(uuidv1(v1options));
		const token = AuthService.getCurrentUser();

		const decoded = jwt_decode(token);
		if (decoded.exp < Date.now() / 1000) {
			this.props.history.push({
				pathname: '/',
				errorMessage: 'Kindly sign in again.',
			});
		}
	}, []);

	const onTransactionName = (e) => {
		setTransactionName(e.target.value);
	};

	const onAmount = (e) => {
		const { value } = e.target;

		setAmount(value);
	};

	const onCurrencySelect = (currency) => {
		setCurrency(currency);
	};

	const onswitch = (e) => {
		setChargeMe(e);
	};

	const onFirstName = (e) => {
		setCustomerFirstName(e.target.value);
	};

	const onLastName = (e) => {
		setCustomerLastName(e.target.value);
	};

	const onEmail = (e) => {
		setCustomerEmail(e.target.value);
	};

	const onPhone = (e) => {
		setCustomerPhone(e.target.value);
	};

	const onWallet = (e) => {
		setWallet(e.target.value);
	};

	const onSubmit = async () => {
		setTransactionReference(uuidv1(v1options));
		const payout = {
			transactionName,
			amount,
			currency,
			transactionReference,
			customerFirstName,
			customerLastName,
			customerEmail,
			customerPhone,
			wallet,
			chargeMe,
		};

		const merchant = await UserService.getMerchant();

		if (merchant.data.id) {
		  const id = merchant.data.id;
		  setLoading(true);
		  const res = await UserService.postPayout(payout, id);
		  setLoading(false);
		  message.success('Payout submitted for processing, please wait for 5 minutes before you can submit another payout.', 6);
		  setTimeout(() => {
			window.location.reload();
		  }, 6000);
		} else {
			setLoading(false);
		  	message.error('Payout failed', 5);
		}
	};

	return (
		<>
		<Spinner loading={loading} />
			<Form
				className='payout-form'
				labelCol={{ span: 8, offset: 6 }}
				scrollToFirstError={true}
				wrapperCol={{ span: 0 }}
				layout='horizontal'
				size='default'>
				<Form.Item
					name='transactionName'
					label='Transaction Name'
					rules={[{ required: true }]}>
					<Input onChange={onTransactionName} />
				</Form.Item>

				<Form.Item
					name='amount'
					label='Amount'
					rules={[{ required: true }]}>
					<Input  onChange={onAmount} />
				</Form.Item>

				<Form.Item label='Currency'>
					<Select name='currency' onSelect={onCurrencySelect}>
						{currencies.map((currency) => (
							<Select.Option
								value={currency}
								onChange={onCurrencySelect}>
								{currency}
							</Select.Option>
						))}
					</Select>
				</Form.Item>

				<Form.Item
					name='customerFirstName'
					label='Customer First Name'
					rules={[{ required: true }]}>
					<Input onChange={onFirstName} />
				</Form.Item>

				<Form.Item
					name='customerLastName'
					label='Customer Last Name'
					rules={[{ required: true }]}>
					<Input onChange={onLastName} />
				</Form.Item>
				<Form.Item
					name='customerEmail'
					label='Contact Email Address'
					rules={[{ required: true }]}>
					<Input onChange={onEmail} />
				</Form.Item>
				<Form.Item
					name='customerPhone'
					label='Contact Phone Number'
					rules={[{ required: true }]}>
					<Input onChange={onPhone} />
				</Form.Item>
				<Form.Item
					name='wallet'
					label='Mobile money wallet'

					rules={[{ required: true }]}>
					<Input onChange={onWallet} placeholder='Phone number to use for payout'/>
				</Form.Item>

				<Form.Item label='charge me?'>
					<Switch onChange={onswitch} />
				</Form.Item>
				<Form.Item wrapperCol={{ offset: 8, span: 16 }}>
					<Button  type="primary" htmlType="submit" className="submit-payout-button" onClick={onSubmit}>
						Submit Payout
					</Button>
				</Form.Item>
			</Form>
		</>
	);
};

export { Payouts };
