import React from 'react';
import 'antd/dist/antd.css';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { Table } from 'antd';

const expColumns = [
	{ title: 'ID', dataIndex: 'id', key: 'id	' },
	{
		title: 'Merchant Reference',
		dataIndex: 'merchantRef',
		key: 'transaction_ref',
	},
];

export const PaymentLinkTable = ({ data, columns, loader, expColumns }) => {
	const customExpandIcon = (props) => {
		if (props.expanded) {
			return (
				<a
					style={{ color: 'black' }}
					onClick={(e) => {
						props.onExpand(props.record, e);
					}}>
					<KeyboardArrowUpIcon />
				</a>
			);
		} else {
			return (
				<a
					style={{ color: 'black' }}
					onClick={(e) => {
						props.onExpand(props.record, e);
					}}>
					<VisibilityIcon />
				</a>
			);
		}
	};
	return (
		<div>
			<Table
				pagination={false}
				rowKey='id'
				dataSource={data}
				loading={loader}
				expandIcon={(props) => customExpandIcon(props)}
				columns={columns}
				expandable={{
					expandedRowRender: (records) => (
						<Table
							pagination={false}
							rowKey='id'
							columns={expColumns}
							dataSource={[records]}
						/>
					),
				}}
			/>
			,
		</div>
	);
};
