import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { Layout } from 'antd';
import './LoginForm.css';
import axios from 'axios';
import { API_URL } from '../../../config';
import { Spinner } from '../../elements/Spinner/Spinner';
import { Link, NavLink } from 'react-router-dom';
import { Input } from 'antd';

const { Header, Content, Footer } = Layout;
// const layout = {
// 	labelCol: {
// 		span: 8,
// 	},
// 	wrapperCol: {
// 		span: 16,
// 	},
// };
// const tailLayout = {
// 	wrapperCol: {
// 		offset: 8,
// 		span: 16,
// 	},
// };

export const LoginForm = ({ errorMessage }) => {
	const history = useHistory();
	const [credentials, setCredentials] = useState({ email: '', password: '' });
	const [loading, setLoading] = useState(false);
	const [message, setMessage] = useState('');
	const [loginError, setLoginError] = useState(false);

	useEffect(() => {
		if (history.location.errorMessage) {
			setMessage(history.location.errorMessage);
		}
	});
	const onSubmit = (event) => {
		event.preventDefault();
		setLoading(true);

		// fetch user
		axios
			.post(`${API_URL}login`, {
				...credentials,
			})
			.then((res) => {
				if (res.data.isError === true) {
					setLoginError(res.data.message);
					return setLoading(false);
				}
				if (res.data.token !== null) {
					localStorage.setItem('token', res.data.token);
					history.push('/dashboard/overview');
					return setLoading(false);
				}
				// this.setState({ message: res.data.message });
			});
	};

	const onchange = (event) => {
		const { name, value } = event.target;

		event.preventDefault();
		setCredentials((values) => ({
			...values,
			[name]: value,
		}));
	};

	const onFinishFailed = (errorInfo) => {
		// console.log('Failed:', errorInfo);
	};

	return (
		<Layout style={{ height: '100vh' }}>
			<Header
				className='site-layout-background'
				style={{
					color: 'white',
					textAlign: 'center',
					fontSize: '20px',
					fontWeight: 'bolder',
				}}>
				<NavLink to='/' style={{ color: 'white' }}>
					Broadpay Gateway Portal
				</NavLink>
			</Header>
			<Layout>
				<Content className='content'>
					<form className='login-form'>
						{loading ? (
							<Spinner />
						) : (
							<div className='LoginPage-Form-Container'>
								<div class='form-group'>
									<label for='InputEmail'>
										Email address
									</label>

									<Input
										// style={{ width: '50%' }}
										name='email'
										type='email'
										class='form-control'
										id='InputEmail'
										aria-describedby='emailHelp'
										placeholder='Enter email'
										onChange={onchange}
									/>
									<small
										id='emailHelp'
										class='form-text text-muted'>
										We'll never share your email with anyone
										else.
									</small>
								</div>
								<div class='form-group'>
									<label for='InputPassword'>Password</label>
									<Input
										// style={{ width: '50%' }}

										name='password'
										type='password'
										class='form-control'
										id='InputPassword'
										placeholder='Password'
										onChange={onchange}
									/>
								</div>
								<div
									className='LoginPage-Form-Buttons-Container'
									// style={{
									// 	display: 'flex',
									// 	flexDirection: 'row',
									// 	justifyContent: 'space-between',
									// 	// marginBottom: '5px',
									// 	// marginRight: '80%',
									// }}
								>
									<button
										// type='submit'
										class='btn btn-primary btn-block'
										onClick={onSubmit}>
										Sign in
									</button>

									<button
										type='button'
										class='btn btn-link'
										style={{
											alignSelf: 'center',
										}}>
										<Link
											to='/forgot-password'
											class='badge-light'>
											{' '}
											Forgot Password
										</Link>
									</button>

									<a href='https://app.lenco.co/login?country=ZM' class='btn btn-link'>New? Sign up</a>

								</div>
								{loginError ? (
									<small
										style={{
											color: 'red',
										}}>
										{loginError}
									</small>
								) : message ? (
									<small
										style={{
											color: 'red',
										}}>
										{message}
									</small>
								) : (
									''
								)}
							</div>
						)}
					</form>
				</Content>
				<Footer
					style={{
						textAlign: 'center',
						backgroundColor: '#001529',
						color: 'white',
					}}>
					Copyright © Broadpay 2023
				</Footer>
			</Layout>
		</Layout>
	);
};
