import './App.css';
import React from 'react';
import { Switch, Route, BrowserRouter } from 'react-router-dom';
import ProtectRoutes from './components/authPages/ProtectRoutes/ProtectRoutes';
import { LoginForm } from './components/authPages/LoginForm/LoginForm';
import { Register } from './components/authPages/Register/Register';
import { SideMenu } from './components/elements/Sider';
import { TransactionsContextProvider } from './components/Contexts/Contexts';
import { ResetPassword } from './components/authPages/ResetPassword/ResetPassword';
import { UpdatePassword } from './components/authPages/UpdatePassword/UpdatePassword';
import { NotFound } from './components/dashboardPages/Errors/NotFound';
import { EmailVerify } from './components/authPages/EmailVerify/EmailVerify';

export const App = () => {
	return (
		<BrowserRouter>
			<Switch>
				<Route path='/' component={LoginForm} exact />
				<Route path='/register' component={Register} exact />
				<Route
					path='/password-reset'
					component={UpdatePassword}
					exact
				/>
				<Route
					path='/forgot-password'
					component={ResetPassword}
					exact
				/>

				<Route path='/confirm/:token' component={EmailVerify} exact />

				<ProtectRoutes>
					<TransactionsContextProvider>
						<SideMenu />
					</TransactionsContextProvider>
				</ProtectRoutes>
				{/* <Route path='' component={NotFound} exact /> */}
			</Switch>
		</BrowserRouter>
	);
};
