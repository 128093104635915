import React, { Component } from 'react';
import authService from '../../../services/authService';
import { Spin, Space } from 'antd';
import { withRouter } from 'react-router-dom';
import jwt_decode from 'jwt-decode';

class ProtectRoutes extends Component {
	state = {
		token: undefined,
		message: null,
	};

	//check if user has token
	componentDidMount() {
		const token = authService.getCurrentUser();
		try {
			const decoded = jwt_decode(token);
			if (decoded.exp && decoded.exp > Date.now() / 1000) {
				return this.setState({ token });
			}

			if (decoded.exp < Date.now() / 1000) {
				this.props.history.push({
					pathname: '/',
					errorMessage: 'Kindly sign in again.',
				});
			}
		} catch (error) {
			console.log(error);
			return this.props.history.push({
				pathname: '/',
				errorMessage: 'kindly signup or login',
			});
		}

		if (!token) {
			this.setState({ message: "Don't try to be smart" });
			return this.props.history.push({
				pathname: '/',
				errorMessage: 'Sign in or Sign up first if you have no account',
			});
		}
		this.setState({ token });
	}

	render() {
		if (this.state.token === undefined)
			return (
				<div className='spinner-container'>
					<div className='spinners'>
						<Space size='middle'>
							<Spin size='small' />
							<Spin />
							<Spin size='large' />
							<p>spinning</p>
						</Space>
					</div>
				</div>
			);

		return <div>{this.props.children}</div>;
	}
}

export default withRouter(ProtectRoutes);
