import React, { Component } from "react";
import AuthService from "../../../services/authService";
import { PaymentLinkTable } from "./PaymentLinkTable";
import UserService from "../../../services/userService";
import jwt_decode from "jwt-decode";
import { Spinner } from "../../elements/Spinner/Spinner";
import { Button, message, Pagination } from "antd";
import { expColumns } from "./PaymentLinkData";
import "./PaymentLinks.css";
import { CreateLink } from "./CreateLink";
import { CopyOutlined } from "@ant-design/icons";
import { CopyToClipboard } from "react-copy-to-clipboard";

class PaymentLinks extends Component {
  state = {
    hasNextPage: "",
    hasPreviousPage: "",
    isError: "",
    itemsPerPage: "",
    links: [],
    message: "",
    page: 0,
    totalLinks: 0,
    totalPages: 0,
    loading: true,
    errorMessage: "",
    create: false,
    value: "",
    copied: false,
    pageNumber: 0,
  };

  componentDidMount() {
    const token = AuthService.getCurrentUser();

    const decoded = jwt_decode(token);
    if (decoded.exp < Date.now() / 1000) {
      this.props.history.push({
        pathname: "/",
        errorMessage: "Kindly sign in again.",
      });
    }
    UserService.getPaymentLinks(this.state.pageNumber)
      .then((res) => {
        this.setState({
          hasNextPage: res.data.hasNextPage,
          hasPreviousPage: res.data.hasPreviousPage,
          isError: res.data.isError,
          itemsPerPage: res.data.itemsPerPage,
          links: [...res.data.links],
          message: res.data.message,
          page: res.data.page,
          totalLinks: res.data.totalLinks,
          totalPages: res.data.totalPages,
          loading: false,
        });
      })
      .catch((error) => {
        this.setState({ errorMessage: error.message }, console.log(this.state));
      });
  }

  onCreate = () => {
    this.setState({ create: true });
  };

  oncopy = () => {
    this.setState({ copied: true });
    return message.success("copied to clipboard", 2);
  };

  columns = [
    { title: "Amount", dataIndex: "amount", key: "Amount" },
    { title: "Date", dataIndex: "created", key: "Date" },
    { title: "Currency", dataIndex: "currency", key: "Currency" },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },

    { title: "Title", dataIndex: "title", key: "title" },
    {
      title: "Copy Payment Link",
      dataIndex: "linkUrl",
      key: "linkUrl",
      render: (dataIndex) => {
        let base = "broadpay";
        const newLink =
          `https://pay.${base}.io?reference=` + dataIndex.substring(25);
        return (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <CopyToClipboard text={newLink} onCopy={this.oncopy}>
              <button
                style={{
                  margin: 2,
                }}
              >
                All
                <CopyOutlined size="10" style={{ color: "blue" }} />
              </button>
            </CopyToClipboard>
            <CopyToClipboard
              text={newLink + "&method=card"}
              onCopy={this.oncopy}
            >
              <button
                style={{
                  margin: 2,
                }}
              >
                Card
                <CopyOutlined size="10" style={{ color: "black" }} />
              </button>
            </CopyToClipboard>
            <CopyToClipboard
              text={newLink + "&method=airtel"}
              onCopy={this.oncopy}
            >
              <button
                style={{
                  margin: 2,
                }}
              >
                Airtel
                <CopyOutlined size="10" style={{ color: "red" }} />
              </button>
            </CopyToClipboard>
            <CopyToClipboard
              text={newLink + "&method=zamtel"}
              onCopy={this.oncopy}
            >
              <button
                style={{
                  margin: 2,
                }}
              >
                Zamtel
                <CopyOutlined size="10" style={{ color: "green" }} />
              </button>
            </CopyToClipboard>
            <CopyToClipboard
              text={newLink + "&method=mtn"}
              onCopy={this.oncopy}
            >
              <button
                style={{
                  margin: 2,
                }}
              >
                MTN
                <CopyOutlined size="10" style={{ color: "yellow" }} />
              </button>
            </CopyToClipboard>
          </div>
        );
      },
    },
    {
      title: "Delete Link",
      dataIndex: "ref",
      key: "ref",
      render: (ref) => (
        <Button
          onClick={async () => {
            const response = await UserService.deletePaymentLink(ref);
            console.log(response);
            message.success("Link Deleted");
            return response ? window.location.reload() : null;
          }}
        >
          delete
        </Button>
      ),
    },
  ];

  render() {
    return (
      <div className="paymentLinks-page">
        {this.state.create ? (
          <CreateLink />
        ) : this.state.loading ? (
          <div className="Spinner">
            <Spinner />
          </div>
        ) : this.state.links.length === 0 ? (
          <>
            <h4 style={{ marginTop: "10%", marginLeft: "40%" }}>
              You have no payment links
            </h4>
            <Button
              type="primary"
              onClick={this.onCreate}
              style={{ marginLeft: "41%" }}
            >
              Create payment Link
            </Button>
          </>
        ) : (
          <>
            <PaymentLinkTable
              data={this.state.links}
              columns={this.columns}
              loader={this.state.loading}
              expColumns={expColumns}
              expData={this.state.links}
            />
            <Pagination
              style={{ alignItems: "center" }}
              showQuickJumper
              showSizeChanger={false}
              defaultCurrent={1}
              total={this.state.totalPages}
              onChange={(page) => {
                this.setState({
                  loading: true,
                  pageNumber: page,
                });
              }}
            />

            <Button
              type="primary"
              style={{ marginBottom: "2%", marginLeft: "41%" }}
              onClick={this.onCreate}
            >
              Create payment Link
            </Button>
          </>
        )}
      </div>
    );
  }
}

export { PaymentLinks };
