// export const columns = [
//     { title: 'ID', dataIndex: 'id', key: 'ID', ...getColumnSearchProps('id') },
//     { title: 'Status', dataIndex: 'status', key: 'Status' },
//     { title: 'Sender', dataIndex: 'sender', key: 'Sender' },
//     { title: 'Service', dataIndex: 'service', key: 'Service' },
//     { title: 'Service Provider', dataIndex: 'service_provider', key: 'ServiceProvider' },
//     { title: 'Amount', dataIndex: 'amount', key: 'Amount' },
//     { title: 'Date', dataIndex: 'updatedAt', key: 'Date' },
// ];
export const expColumns = [
	{
		title: 'First Name',
		dataIndex: 'firstName',
		key: 'firstname',
	},
	{
		title: 'Last Name',
		dataIndex: 'lastName',
		key: 'lastname',
	},
	{
		title: 'Contact Number',
		dataIndex: 'phone',
		key: 'phone',
	},
	{
		title: 'Email',
		dataIndex: 'email',
		key: 'email',
	},
	{
		title: 'City',
		dataIndex: 'city',
		key: 'city',
	},
	{
		title: 'Country',
		dataIndex: 'country',
		key: 'country',
	},
	{
		title: 'Merchant Reference',
		dataIndex: 'merchantRef',
		width: '20%',
		key: 'transaction_ref',
		responsive: ['md'],
	},
	{ title: 'Amount', dataIndex: 'amount', key: 'Amount' },
	// { title: 'Date', dataIndex: 'created', key: 'Date' },
	{
		title: 'Currency',
		dataIndex: 'currency',
		key: 'Currency',
		responsive: ['md'],
	},
	{ title: 'Product', dataIndex: 'name', key: 'name' },
	{
		title: 'Payment Method',
		dataIndex: 'paymentMethod',
		key: 'paymentMethod',
		responsive: ['md'],
	},
	{
		title: 'Status',
		dataIndex: 'status',
		key: 'Status',
	},
];

export const SettlementColumns = [
	{
		title: 'Amount',
		dataIndex: 'amount',
		key: 'amount',
	},
	{
		title: 'Status',
		dataIndex: 'status',
		key: 'status',
	},
	{
		title: 'Reference',
		dataIndex: 'reference',
		key: 'reference',
	},
	{
		title: 'Date',
		dataIndex: 'created',
		key: 'date',
	},
	{
		title: 'Currency',
		dataIndex: 'currency',
		key: 'currency',
	},
];
