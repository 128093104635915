import axios from 'axios';
import { API_URL } from '../config';

class AuthService {
	login(email, password) {
		console.log(`${API_URL}login`);
		return axios
			.post(`${API_URL}login`, {
				email,
				password,
			})
			.then((res) => {
				if (res.data.token !== null) {
					localStorage.setItem('token', res.data.token);
					// this.props.history.push('/transactions');
				} else {
					console.log(res.data.message);
					window.location.reload();
				}
			});
	}

	logout() {
		localStorage.removeItem('token');
		window.location.reload();
	}

	register(details) {
		return axios.post(API_URL + 'signup', { ...details });
	}

	getCurrentUser() {
		return localStorage.getItem('token');
	}
	//request reset password
	postResetPassword(details) {
		return axios.post(API_URL + 'password/reset', { ...details });
	}

	//update password
	postNewPassword(password, token) {
		return axios.post(`${API_URL}password/reset/${token}`, { password });
	}
}

export default new AuthService();
