import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { Button, Form, Input, Upload, message } from 'antd';
import UserService from '../../../services/userService';
import authHeader from '../../../services/authHeader';
import './Account.css';
import jwt_decode from 'jwt-decode';
import AuthService from '../../../services/authService';
import { Spinner } from '../../elements/Spinner/Spinner';
import TextArea from 'antd/lib/input/TextArea';
import { UploadOutlined } from '@ant-design/icons';

const layout = {
	labelCol: {
		span: 8,
	},
	wrapperCol: {
		span: 16,
	},
};
// const tailLayout = {
// 	wrapperCol: {
// 		offset: 8,
// 		span: 16,
// 	},
// };

export const Account = (props) => {
	const history = useHistory();
	const [details, setDetails] = useState({
		isError: true,
		name: '',
		email: '',
		phone: '',
		address: '',
		merchantCountryName: '',
		accountCurrency: '',
		description: '',
		site_url: '',
		webhookUrl: '',
		edit: true,
		errorInfo: false,
	});
	// eslint-disable-next-line
	const [loading, setLoading] = useState(false);
	// eslint-disable-next-line
	const [fileList, setFileList] = useState([]);
	// eslint-disable-next-line
	const [imageUrl, setImageUrl] = useState('');

	const [kycFiles, setKycFiles] = useState([]);

	function getBase64(img, callback) {
		const reader = new FileReader();
		reader.addEventListener('load', () => callback(reader.result));
		reader.readAsDataURL(img);
	}

	const handleUploadChange = (info) => {
		if (info.file.status === 'uploading') {
			setLoading(true);
			return;
		}
		if (info.file.status === 'done') {
			// Get this url from response in real world.
			getBase64(
				info.file.originFileObj,
				(imageUrl) => setImageUrl(imageUrl),
				setLoading(false),
			);
		}
	};

	const onSave = async () => {
		setDetails({ edit: true });
		// const url = { site: details.site_url };
		// console.log(url);
		const res = await UserService.updateProfile(details);

		res.isError
			? message.warning('Update failed please try again', 3)
			: message.success('Profile updated', 3);

		// window.location.reload();
	};

	const onChange = (input) => {
		const { name, value } = input.target;
		console.log(name, ':', value);
		setDetails((values) => {
			return { ...values, [name]: value };
		});
		console.log(details);
	};

	const onSiteUrl = (event) => {
		setDetails((values) => {
			return { ...values, site_url: event.target.value };
		});
	};

	const onEdit = () => {
		setDetails({ edit: false });
	};

	useEffect(async () => {
		const token = AuthService.getCurrentUser();
		const decoded = jwt_decode(token);
		if (decoded.exp < Date.now() / 1000) {
			history.push({
				pathname: '/',
				errorMessage: 'Kindly sign in again.',
			});
		}

		const kyc = await UserService.getFiles();
		setKycFiles([...kyc.data.files]);

		const merchant = await UserService.getMerchant();
		setDetails({
			name: merchant.data.name,
			email: merchant.data.email,
			phone: merchant.data.phone,
			address: merchant.data.address,
			merchantCountryName: merchant.data.merchantCountryName,
			accountCurrency: merchant.data.accountCurrency,
			site_url: merchant.data.siteUrl,
			webhookUrl: merchant.data.webhookUrl,
			description: merchant.data.description,
			isError: merchant.data.isError,
			edit: true,
		});
	}, []);
	const onFinish = async () => {
		// const response = await UserService.postAddBank(details);
		// const message = response.data.message;
		// setDetails({
		// 	message: response.data.message,
		// 	isError: response.data.isError,
		// });
		// if (!details.isError) {
		// 	props.history.push({
		// 		pathname: '/dashboard/bank-accounts',
		// 		message: message,
		// 	});
		// }
	};

	const onFinishFailed = (errorInfo) => {
		setDetails({ errorInfo: true });
	};

	const profile = [
		'Name',
		' Email',
		' Phone',
		' Address',
		'Country',
		' Currency',
		' Website',
		' webhook',
		'description',
	];

	return (
		<>
			{!details.isError ? (
				<>
					<Form
						{...layout}
						name='basic'
						initialValues={{
							remember: true,
						}}
						onFinish={onFinish}
						onFinishFailed={onFinishFailed}
						className='form-account'
						style={
							{
								// width: '100%',
								// marginLeft: '12%',
							}
						}>
						<div
							style={
								{
									// margin: '0 auto',
									// display: 'flex',
									// flexDirection: 'column',
									// backgroundColor: 'yellow',
								}
							}>
							<Form.Item
								label='Name'
								name='name'
								rules={[
									{
										required: true,
										message:
											'Please enter the name of the account holder!',
									},
								]}
								onFinishFailed={onFinishFailed}>
								<Input
									name='name'
									onChange={onChange}
									defaultValue={details.name}
									readOnly={details.edit}
								/>
							</Form.Item>
							<Form.Item
								label='Email'
								name='email'
								rules={[
									{
										required: true,
										message:
											'Please enter the name of the account holder!',
									},
								]}
								onFinishFailed={onFinishFailed}>
								<Input
									name='email'
									onChange={onChange}
									defaultValue={details.email}
									readOnly={details.edit}
								/>
							</Form.Item>

							<Form.Item
								label='Phone'
								name='phone'
								rules={[
									{
										required: true,
										message: 'Please add contact number!',
									},
								]}>
								<Input
									name='phone'
									type='number'
									onChange={onChange}
									readOnly={details.edit}
									defaultValue={details.phone}
								/>
							</Form.Item>
							<Form.Item
								label='Address'
								name='address'
								rules={[
									{
										required: true,
										message: 'Please add address!',
									},
								]}>
								<Input
									name='address'
									type='text'
									onChange={onChange}
									readOnly={details.edit}
									defaultValue={details.address}
								/>
							</Form.Item>
							<Form.Item
								label='Country'
								name='merchantCountryName'
								rules={[
									{
										required: true,
										message: 'Please add country!',
									},
								]}>
								<Input
									name='merchantCountryName'
									onChange={onChange}
									readOnly={details.edit}
									defaultValue={details.merchantCountryName}
								/>
							</Form.Item>
							<Form.Item
								label='Currency'
								name='currency'
								rules={[
									{
										required: true,
										message: 'Please add currency!',
									},
								]}>
								<Input
									name='accountCurrency'
									onChange={onChange}
									readOnly={details.edit}
									defaultValue={details.accountCurrency}
								/>
							</Form.Item>

							<Form.Item label='Website' name='site_url'>
								<Input
									name='site_url'
									onChange={onChange}
									readOnly={details.edit}
									defaultValue={details.site_url}
								/>
							</Form.Item>

							<Form.Item label='Webhook URL' name='webhookUrl'>
								<Input
									name='webhookUrl'
									onChange={onChange}
									readOnly={details.edit}
									defaultValue={details.webhookUrl}
								/>
							</Form.Item>

							<Form.Item label='Description' name='description'>
								<TextArea
									rows={6}
									name='description'
									onChange={onChange}
									readOnly={details.edit}
									defaultValue={details.description}
								/>
							</Form.Item>
						</div>
						<Form.Item className='form-account-edit-save'>
							{details.edit ? (
								<Button type='primary' onClick={onEdit}>
									Edit Details
								</Button>
							) : (
								<Button type='primary' onClick={onSave}>
									Save Details
								</Button>
							)}
						</Form.Item>

						<Form.Item
							name={['user', 'NRC']}
							label='Upload KYC Files'
							rules={[{ required: true }]}>
							<Upload
								action='https://dashboard.broadpay.io/api/v1/files/upload'
								name='files'
								showUploadList={true}
								headers={authHeader()}
								onChange={handleUploadChange}>
								<Button icon={<UploadOutlined />}>
									Upload
								</Button>
							</Upload>
						</Form.Item>
					</Form>
					{/* <ul style={{ float: 'right ', paddingBottom: '20%' }}>
						{kycFiles.map((el) => (
							<li>{el.name}</li>
						))}
					</ul> */}
				</>
			) : (
				<Spinner />
			)}
		</>
	);
};
