import React, { Component } from 'react';
import UserService from '../../../services/userService';
// import { Tables } from '../../elements/Table/Tables';
import { Spinner } from '../../elements/Spinner/Spinner';
import jwt_decode from 'jwt-decode';
import AuthService from '../../../services/authService';
import { Table, SearchTableInput } from 'ant-table-extensions';
import { SettlementColumns } from '../../../data/transactionData';

import './Settlements.css';
// import { Table } from 'antd';

class Settlements extends Component {
	state = {
		hasNextPage: false,
		hasPreviousPage: false,
		isError: false,
		itemsPerPage: 0,
		message: '',
		page: 0,
		settlements: [],
		totalPages: 0,
		totalSettlements: 0,
		totalTransactions: 0,
		transactions: [],
		loading: false,
	};

	componentDidMount() {
		const token = AuthService.getCurrentUser();

		const decoded = jwt_decode(token);
		if (decoded.exp < Date.now() / 1000) {
			this.props.history.push({
				pathname: '/',
				errorMessage: 'Kindly sign in again.',
			});
		}
		this.setState({ loading: true });
		UserService.getSettlements()
			.then((res) =>
				this.setState({
					hasNextPage: res.data.hasNextPage,
					hasPreviousPage: res.data.hasPreviousPage,
					isError: res.data.isError,
					itemsPerPage: res.data.itemsPerPage,
					message: res.data.message,
					page: res.data.page,
					totalTransactions: res.data.totalsettlements,
					transactions: [...res.data.transactions],
					settlements: [...res.data.settlements],
					loading: false,
				}),
			)
			.catch((error) => console.log(error.message));
	}

	render() {
		return (
			<div className='settlements-page'>
				{/* {this.state.loading ? (
					<div className='Spinner'>
						<Spinner />
					</div>
				) : (
					// <Table
					// 	className='tables'
					// 	tableData={this.state.settlements}
					// /> */}

				{/* <Table
					size='small'
					pagination={false}
					rowKey='id'
					columns={SettlementColumns}
					dataSource={this.state.settlements}
					exportable
				/> */}

				<Table
					size='small'
					bordered
					loading={true}
					scroll={{ y: 600, x: 700 }}
					showHeader={true}
					// sticky={true}
					selections={true}
					pagination={false}
					rowKey='id'
					dataSource={this.state.settlements.map(
						(element) => element,
					)}
					loading={this.state.loading}
					columns={SettlementColumns}
					// expandRowByClick={true}
				/>
			</div>
		);
	}
}

export { Settlements };
