import React, { Component } from 'react';
// import { DetailedGraph } from '../../elements/DetailedGraph/DetailedGraph';
// import { LineCharts } from '../../elements/LineChart/LineChart';
import { OverviewCard } from '../../elements/OverviewCard/OverviewCard';
import UserService from '../../../services/userService';
import AuthService from '../../../services/authService';
import jwt_decode from 'jwt-decode';
import { Spinner } from '../../elements/Spinner/Spinner';
import './Overview.css';
// import { BarChart } from '../../elements/Graphs/BarChart';

const data = [
	{
		year: '1991',
		value: 3,
	},
	{
		year: '1992',
		value: 4,
	},
	{
		year: '1993',
		value: 5,
	},
	{
		year: '1994',
		value: 6,
	},
	{
		year: '1995',
		value: 3,
	},
	{
		year: '1996',
		value: 8,
	},
	{
		year: '1997',
		value: 9,
	},
	{
		year: '1998',
		value: 2,
	},
	{
		year: '1999',
		value: 23,
	},
	{
		year: '2000',
		value: 4,
	},
	{
		year: '2001',
		value: 5,
	},
	{
		year: '2002',
		value: 6,
	},
	{
		year: '2003',
		value: 3,
	},
	{
		year: '2004',
		value: 8,
	},
	{
		year: '2005',
		value: 9,
	},
	{
		year: '2006',
		value: 2,
	},

	//...
];
export class Overview extends Component {
	state = {
		totalSuccessfulPaymentAmount: 0,
		totalSuccessfulPayoutAmount: 0,
		ledgerBalance: 0,
		currency: '',
		availableBalance: 0,
		totalPaymentCount: 0,
		totalPayoutCount: 0,
		floatBalance: 0,
		totalPayouts: 0,
		totalTransactions: 0,
		transactions: [],
		'MTN Money': 0,
		CARD: 0,
		'other mobile money': 0,
		loading: true,
		Jan: { month: '', value: 0, amount: 0 },
		Feb: { month: '', value: 0, amount: 0 },
		Mar: { month: '', value: 0, amount: 0 },
		Apr: { month: '', value: 0, amount: 0 },
		May: { month: '', value: 0, amount: 0 },
		Jun: { month: '', value: 0, amount: 0 },
		Jul: { month: '', value: 0, amount: 0 },
		Aug: { month: '', value: 0, amount: 0 },
		Sep: { month: '', value: 0, amount: 0 },
		Oct: { month: '', value: 0, amount: 0 },
		Nov: { month: '', value: 0, amount: 0 },
		Dec: { month: '', value: 0, amount: 0 },
	};
	Bottom = [
		{
			'Payment Count': { value: this.state.totalPaymentCount },
		},
		{
			'Payout Count': {},
		},
		{
			'Total Transactions': {},
		},
		{
			'Float Balance': { value: this.state.floatBalance },
		},
	];

	Top = [
		{
			'Total Earnings': { value: null },
		},
		{
			'Total Amount Paid Out': {
				value: this.state.totalSuccessfulPayoutAmount,
			},
		},
		{
			'Ledger Balance': { value: this.state.ledgerBalance },
		},
		{
			'Actual Balance': { value: null },
		},
	];
	async componentDidMount() {
		const token = AuthService.getCurrentUser();

		const decoded = jwt_decode(token);
		if (decoded.exp < Date.now() / 1000) {
			this.props.history.push({
				pathname: '/',
				errorMessage: 'Kindly sign in.',
			});
		}

		const merchant = await UserService.getMerchant();
		const {
			floatBalance,
			totalPaymentCount,
			totalPayoutCount,
			totalPayouts,
			totalTransactions,
		} = merchant.data;

		this.setState({
			totalSuccessfulPaymentAmount:
				merchant.data.totalSuccessfulPaymentAmount,
			totalSuccessfulPayoutAmount:
				merchant.data.totalSuccessfulPayoutAmount,
			ledgerBalance: merchant.data.ledgerBalance,
			availableBalance: merchant.data.availableBalance,
			currency: merchant.data.accountCurrency,
			floatBalance,
			totalPaymentCount,
			totalPayoutCount,
			totalPayouts,
			totalTransactions,
		});
		const transactions = await UserService.getTransactions();
		// const totalPages = firstRound.data.totalPages;
		// const itemsPerPage = 1 * f;
		// const transactions = await UserService.getTransactions(itemsPerPage);

		transactions.data.transactions.forEach((transaction) => {
			const transact = transaction.created.split(' ')[2];
			if (transaction.status === 'processed') {
				if (transaction.paymentMethod === 'MTN') {
					this.setState({ 'MTN Money': this.state['MTN Money'] + 1 });
				} else if (transaction.paymentMethod === 'CARD') {
					this.setState({ CARD: this.state['CARD'] + 1 });
				} else {
					this.setState({
						'other mobile money':
							this.state['other mobile money'] + 1,
					});
				}
				switch (transact) {
					case 'Jan':
						this.setState({
							Jan: {
								month: transact,
								value: this.state.Jan.value + 1,
								amount:
									this.state.Jan.amount + transaction.amount,
							},
						});

						break;
					case 'Feb':
						return this.setState({
							Feb: {
								month: transact,
								value: this.state.Feb.value + 1,
								amount:
									this.state.Feb.amount + transaction.amount,
							},
						});
						break;
					case 'Mar':
						this.setState({
							Mar: {
								month: transact,
								value: this.state.Mar.value + 1,
								amount:
									this.state.Mar.amount + transaction.amount,
							},
						});
						break;
					case 'Apr':
						this.setState({
							Apr: {
								month: transact,
								value: this.state.Apr.value + 1,
								amount:
									this.state.Apr.amount + transaction.amount,
							},
						});
						break;
					case 'May':
						this.setState({
							May: {
								month: transact,
								value: this.state.May.value + 1,
								amount:
									this.state.May.amount + transaction.amount,
							},
						});
						break;
					case 'Jun':
						this.setState({
							Jun: {
								month: transact,
								value: this.state.Jun.value + 1,
								amount:
									this.state.Jun.amount + transaction.amount,
							},
						});
						break;
					case 'Jul':
						this.setState({
							Jul: {
								month: transact,
								value: this.state.Jul.value + 1,
								amount:
									this.state.Jul.amount + transaction.amount,
							},
						});
						break;
					case 'Aug':
						this.setState({
							Aug: {
								month: transact,
								value: this.state.Aug.value + 1,
								amount:
									this.state.Aug.amount + transaction.amount,
							},
						});
						break;
					case 'Sep':
						this.setState({
							Sep: {
								amount:
									this.state.Nov.amount + transaction.amount,
								month: transact,
								value: this.state.Sep.value + 1,
								amount:
									this.state.Sep.amount + transaction.amount,
							},
						});
						break;
					case 'Oct':
						this.setState({
							Oct: {
								month: transact,
								value: this.state.Oct.value + 1,
								amount:
									this.state.Oct.amount + transaction.amount,
							},
						});
						break;
					case 'Nov':
						this.setState({
							Nov: {
								month: transact,
								value: this.state.Nov.value + 1,
								amount:
									this.state.Nov.amount + transaction.amount,
							},
						});
						break;
					case 'Dec':
						this.setState({
							Dec: {
								month: transact,
								value: this.state.Dec.value + 1,
								amount:
									this.state.Dec.amount + transaction.amount,
							},
						});
						break;
					default:
						break;
				}
			}
		});

		this.setState({
			transactions: [
				this.state.Jan,
				this.state.Feb,
				this.state.Mar,
				this.state.Apr,
				this.state.May,
				this.state.Jun,
				this.state.Jul,
				this.state.Aug,
				this.state.Sep,
				this.state.Oct,
				this.state.Nov,
				this.state.Dec,
			],
		});
	}
	data = this.state.transactions;
	render() {
		return (
			<div className='overview-container'>
				<div
					className='overviewcards-top'
					style={{
						display: 'flex',
						justifyContent: 'space-evenly',
					}}>
					{/* Total Earnings */}
					<OverviewCard
						key={Math.random()}
						// data={this.state.transactions}
						title='Float Balance'
						total={this.state.floatBalance}
						currency={this.state.currency}
					/>

					<OverviewCard
						key={Math.random()}
						// data={this.data}
						title={'Total Amount Paid Out'}
						total={this.state.totalSuccessfulPayoutAmount}
						currency={this.state.currency}
					/>

					<OverviewCard
						key={Math.random()}
						// data={data}
						title='Ledger Balance'
						total={this.state.ledgerBalance}
						currency={this.state.currency}
					/>

					<OverviewCard
						key={Math.random()}
						// data={data}
						title='Actual Balance'
						total={this.state.availableBalance}
						currency={this.state.currency}
					/>
				</div>

				<div
					style={{
						display: 'flex',
						justifyContent: 'space-evenly',
					}}>
					{/* <LineCharts /> */}
				</div>
				<div
					className='overviewcards-bottom'
					style={{
						display: 'flex',
						justifyContent: 'space-evenly',
					}}>
					<OverviewCard
						key={Math.random()}
						// data={data}
						title='Total Earnings'
						total={this.state.totalSuccessfulPaymentAmount}
						currency={this.state.currency}
					/>

					<OverviewCard
						key={Math.random()}
						// data={data}
						title='Payment Count'
						total={this.state.totalPaymentCount}
						currency=''
					/>

					<OverviewCard
						key={Math.random()}
						// data={data}
						title='Payout Count'
						total={this.state.totalPayoutCount}
						currency=''
					/>

					<OverviewCard
						key={Math.random()}
						// data={data}
						title='Total Transactions'
						total={this.state.totalTransactions}
						currency=''
					/>
				</div>
				{/* <BarChart /> */}
				{/* <Layout
							style={{
								display: 'flex',
								flexDirection: 'row',
							}}>
							<DetailedGraph data={this.state.transactions} />
							<DetailedGraph style={{ float: 'right' }} />
							<Doubleaxes />
							<Doubleaxes />
						</Layout> */}
				{/* </>
				)} */}
			</div>
		);
	}
}
