import React, { useEffect, useState } from 'react';
import { TransactionsTable } from './TransactionTable';
import { expColumns } from '../../../data/transactionData';
import UserService from '../../../services/userService';
import { Pagination } from 'antd';
import { DateRange } from '../../elements/DateRange/DateRange';

const Transactions = () => {
  const [pageNumber, setPageNumber] = useState(1);
  const [transaction, setTransaction] = useState({
    hasNextPage: false,
    hasPreviousPage: false,
    isError: true,
    itemsPerPage: 0,
    message: '',
    totalTransactions: 0,
    transactions: [],
    loading: true, // Change initial state for loading
  });

  const fetchData = async (page) => {
    try {
      const res = await UserService.getTransactionsOnPages(page);
      setTransaction({
        hasNextPage: res.data.hasNextPage,
        hasPreviousPage: res.data.hasPreviousPage,
        isError: res.data.isError,
        itemsPerPage: res.data.itemsPerPage,
        message: res.data.message,
        totalTransactions: res.data.totalTransactions,
        transactions: res.data.transactions,
        loading: false, // Set loading to false after data is fetched
      });
    } catch (err) {
      window.location.reload();
    }
  };

  useEffect(() => {
    fetchData(pageNumber); // Fetch data when pageNumber changes
  }, [pageNumber]);
  
	const columns = [
		{
			title: 'Merchant Reference',
			dataIndex: 'merchantRef',
			width: '20%',
			key: 'transaction_ref',
			responsive: ['md'],
		},
		{ title: 'Amount', dataIndex: 'amount', key: 'Amount' },
		{ title: 'Date', dataIndex: 'created', key: 'Date' },
		{
			title: 'Currency',
			dataIndex: 'currency',
			key: 'Currency',
			responsive: ['md'],
		},
		{ title: 'Product', dataIndex: 'name', key: 'name' },
		{
			title: 'Payment Method',
			dataIndex: 'paymentMethod',
			key: 'paymentMethod',
			responsive: ['md'],
		},
		{
			title: 'Status',
			dataIndex: 'status',
			key: 'Status',
			filters: [
				{ text: 'Processed', value: 'processed' },
				{ text: 'Failed', value: 'failed' },
				{ text: 'Processing', value: 'processing' },
			],
			onFilter: (value, record) => {
				return record.status === value;
			},
		},
	];

	//Declare the menu

  return (
    <React.Fragment>
      <div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end', // Change float to justify-content
          }}
        >
          {/* Date range component */}
          <div style={{ marginLeft: '2%' }}>
            <DateRange />
          </div>
        </div>

        <div>
          <TransactionsTable
            data={transaction.transactions}
            columns={columns}
            loader={transaction.loading}
            expColumns={expColumns}
            expData={transaction.transactions}
          />
          <Pagination
            style={{ alignItems: 'center', marginTop: '10px' }} // Add some margin-top for spacing
            showQuickJumper
            showSizeChanger={false}
            current={pageNumber} // Use current instead of defaultCurrent
            total={transaction.totalTransactions}
            onChange={(page) => {
              setTransaction({ loading: true });
              setPageNumber(page);
            }}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export { Transactions };
