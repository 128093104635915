import React from 'react';
import ClipLoader from 'react-spinners/ClipLoader';

export const Spinner = ({ children, loading = true }) => {
  const overlayStyles = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'rgba(0, 0, 0, 0.5)', // Semi-transparent black overlay
    display: loading ? 'block' : 'none', // Show the overlay only when loading
    zIndex: 9998, // Adjust the z-index as needed (lower than the spinner)
  };

  const spinnerStyles = {
    position: 'fixed',
    top: '40%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 9999, // Adjust the z-index as needed (higher than the overlay)
  };

  // Define the color for the spinner
  const spinnerColor = '#0000'; // Replace with your desired blue color

  return (
    <>
      <div style={overlayStyles}></div>
      <div style={spinnerStyles}>
        <ClipLoader loading={loading} size={35} aria-label="Loading Spinner" data-testid="loader" />
        {children}
      </div>
    </>
  );
};
