import React from 'react';
import { Table } from 'antd';

const columns = [
	{
		title: 'Account Name',
		dataIndex: 'accountHolder',
		render: (text) => <a>{text}</a>,
	},
	{
		title: 'Account Number',
		dataIndex: 'accountNumber',
	},
	{
		title: 'Swift Code',
		dataIndex: 'swiftCode',
	},
	{
		title: 'Sort Code',
		dataIndex: 'sortCode',
	},
	{
		title: 'Currency',
		dataIndex: 'currency',
	},
	{
		title: 'Bank Name',
		dataIndex: 'bankName',
	},
];

export const AccountTable = ({ bankAccounts }) => {
	const data = [];
	bankAccounts.map((account) => {
		account['key'] = Math.random();
		data.push(account);
	});
	return (
		<div>
			<Table columns={columns} dataSource={data} />
		</div>
	);
};
