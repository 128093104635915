import React, { useState, useEffect, useContext } from 'react';
import moment from 'moment';
import { DatePicker, Space, message } from 'antd';
import UserService from '../../../services/userService';
import { dateFilter } from '../../../utils';
import { CSVLink } from 'react-csv';
import { TransactionsContext } from '../../Contexts/Contexts';

const { RangePicker } = DatePicker;

function range(start, end) {
	const result = [];
	for (let i = start; i < end; i++) {
		result.push(i);
	}
	return result;
}

export const DateRange = () => {
	const {
		allTransactions,
		setAllTransactions,
		rawTransactions,
		setRawTransactions,
		// setLoading,
	} = useContext(TransactionsContext);
	// //state used to control display of button
	// const [loading, setLoading] = useState(false);

	//merchantName state is passed to the csv download,
	//it is used to give a uniquie name to every transaction csv download
	const [merchantName, setMerchantName] = useState('');

	//state to keep array of fetched transactions
	const [transaction, setTransaction] = useState([]);

	const [end, setEnd] = useState('');
	const [load, setLoad] = useState('');

	const [filteredTransactions, setFilteredTransactions] = useState([]);

	//ComponentDidMOunt
	useEffect(async () => {
		// setLoading(true);
		//fetch merchant and get the first name before any space then set merchantName state
		// const merchant = await UserService.getMerchant();
		// setMerchantName(merchant.data.name.split(' ')[0]);

		//fetch transactions with default pagination
		const transact = await UserService.getTransactions(14);

		//Get product of items per page by total pages
		const totalTransactions =
			transact.data.totalPages * transact.data.itemsPerPage;

		//Fetch all transactions with items-per-page query equal to totalTransactions
		// const allTransactions = await UserService.getTransactions(
		// 	totalTransactions,
		// );

		//Set transaction state with response
		// await setTransaction(allTransactions.data.transactions);
		// setRawTransactions(allTransactions.data.transactions);

		// setLoading(false);
	}, []);

	const disabledDate = (current) => {
		// Can not select days before today and today
		return current && current > moment().endOf('day');
	};

	const onSelectDates = (dates, dateStrings) => {
		message
			.loading('Loading Transactions..', 2.5)
			.then(() => message.success('Loading complete', 2.5));
		// .then(() => message.info('Loading finished is finished', 2.5));

		setEnd(dateStrings[1]);
		setAllTransactions(dateFilter(dateStrings, transaction));
		return setFilteredTransactions(dateFilter(dateStrings, transaction));
	};

	//Create CSV file headers
	const headers = [
		{ label: 'Transaction ID', key: 'id' },
		{ label: 'Date', key: 'created' },
		{ label: 'Amount', key: 'amount' },
		{ label: 'Currency', key: 'currency' },
		{ label: 'Merchant Reference', key: 'merchantRef' },
		{ label: 'Name', key: 'name' },
		{ label: 'Payment Method', key: 'paymentMethod' },
		{ label: 'Status', key: 'status' },
		{ label: 'Type', key: 'type' },
	];
	return (
		<>
			<Space direction='vertical' size={12}>
				{/* <RangePicker
				format='YYYY-MM-DD HH:mm:ss'
				onChange={onSelectDates}
			/> */}

				<RangePicker
					className='ant-picker-cell-inner'
					disabledDate={disabledDate}
					// disabledTime={disabledRangeTime}
					showTime={{
						defaultValue: [
							moment('00:00:00', 'HH:mm:ss'),
							moment('11:59:59', 'HH:mm:ss'),
						],
					}}
					format='YYYY-MM-DD HH:mm:ss'
					onChange={onSelectDates}
					showToday
					separator=' '
					// onChange={(dates, dateStrings) => console.log(dateStrings)}
				/>
				{/* {end === '' ? (
					''
				) : (
					<CSVLink
						style={{ width: '100%' }}
						data={filteredTransactions}
						headers={headers}
						filename={`${merchantName}_transactions.csv`}
						className='btn btn-primary'>
						Download Selected Transactions
					</CSVLink>
				)} */}
			</Space>
		</>
	);
};
