import React from 'react';
import { Chart, LineAdvance, Interval } from 'bizcharts';

import { Card, Typography } from 'antd';
const { Title } = Typography;

export const OverviewCard = (props) => {
	const colorChanger = () => {
		if (props.title === 'Total Earnings') {
			return 'red';
		}
		if (props.title === 'Total Amount Paid Out') {
			return '#69e4a6';
		}
		if (props.title === 'Ledger Balance') {
			return '#69e4a6';
		}
		if (props.title === 'Actual Balance') {
			return '#a3a1fb';
		}
		if (props.title === 'MobileMoney') {
			return 'green';
		}
		return '#4756b8';
	};
	if (props.title === 'Total Earnings') {
		return (
			<>
				<Card
					size='small'
					title={
						<strong style={{ color: '#3f51b5', fontSize: '18px' }}>
							{props.title}
						</strong>
					}
					extra={props.filter}
					style={{
						width: 350,
						margin: '20px 10px',
						boxShadow:
							'0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19)',
					}}>
					<div>
						<Title
							strong
							level={5}
							type='secondary'
							style={{ color: 'black' }}>
							{`${props.currency}${' '}${props.total}`}{' '}
						</Title>
					</div>
				</Card>
			</>
		);
	}

	return (
		<>
			<Card
				size='small'
				title={
					<strong style={{ color: '#3f51b5', fontSize: '18px' }}>
						{props.title}
					</strong>
				}
				extra={props.filter}
				style={{
					width: 350,
					margin: '20px 10px',
					boxShadow:
						'0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19)',
				}}>
				<div
					style={{
						display: 'flex',
						justifyContent: 'space-between',
					}}>
					<Title
						strong
						level={5}
						type='secondary'
						style={{ color: 'black' }}>
						{`${props.currency}${' '}${props.total}`}{' '}
					</Title>
				</div>
			</Card>
		</>
	);
};
