import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { Layout, Input } from "antd";
import "./ResetPassword.css";
import axios from "axios";
import { API_URL, host } from "../../../config";
import { Spinner } from "../../elements/Spinner/Spinner";
import { Link, NavLink } from "react-router-dom";
import UserService from "../../../services/userService";
import authService from "../../../services/authService";
import Form from "antd/lib/form/Form";
import { MatchEmail } from "../../../utils";

const { Header, Content, Footer } = Layout;

export const ResetPassword = ({ errorMessage }) => {
  const history = useHistory();
  const [credentials, setCredentials] = useState({
    email: "",
    accType: "",
    host: host,
  });
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(false);

  useEffect(() => {
    if (history.location.errorMessage) {
      setMessage(history.location.errorMessage);
    }
  });
  const onSubmit = async (event) => {
    console.log(credentials);
    event.preventDefault();
    if (!isValidEmail) {
      setResponseMessage("");
      return setMessage("Please enter a valid email");
    }
    setLoading(true);
    setCredentials((values) => {
      return { ...values, accType: "merchant" };
    });

    const response = await authService.postResetPassword(credentials);

    setResponseMessage(response.data.message);
    setLoading(false);
    console.log(response);
  };

  const onchange = (event) => {
    const { name, value } = event.target;
    event.preventDefault();
    setCredentials((values) => ({
      ...values,
      [name]: value,
    }));
    setIsValidEmail(MatchEmail(value));
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Layout style={{ height: "100vh" }}>
      <Header
        className="site-layout-background"
        style={{
          color: "white",
          textAlign: "center",
          fontSize: "20px",
          fontWeight: "bolder",
        }}
      >
        <NavLink to="/" style={{ color: "white" }}>
          Broadpay Gateway Portal
        </NavLink>
      </Header>
      <Layout>
        <Content className="content">
          <form className="login-form">
            <div className="ResetPasswordPge-Input-Parent-Div">
              <div class="form-group">
                <label for="InputEmail">Email address</label>

                <Input
                  // style={{ width: '50%' }}
                  name="email"
                  type="email"
                  class="form-control"
                  id="InputEmail"
                  aria-describedby="emailHelp"
                  placeholder="Enter email"
                  onChange={onchange}
                  required={true}
                />
              </div>
              {/* Reset password button */}
              <button
                style={{ width: "70%" }}
                class="btn btn-primary btn-block"
                onClick={onSubmit}
              >
                Reset Password
              </button>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <button
                  type="button"
                  class="btn btn-link"
                  style={{
                    alignSelf: "center",
                  }}
                >
                  <Link to="/" class="badge-light">
                    {" "}
                    Sign In
                  </Link>
                </button>
				<a href='https://app.lenco.co/login?country=ZM' class='btn btn-link'>New? Sign up</a>
              </div>
              {responseMessage ? (
                <strong
                  style={
                    responseMessage.includes("Invalid")
                      ? {
                          color: "red",
                          paddingLeft: "10%",
                        }
                      : {
                          color: "green",
                          paddingLeft: "10%",
                          width: "min-content",
                        }
                  }
                >
                  {responseMessage}
                </strong>
              ) : message ? (
                <small
                  style={{
                    color: "red",
                    paddingLeft: "30%",
                  }}
                >
                  {message}
                </small>
              ) : (
                ""
              )}
            </div>
            {/* )} */}
          </form>
        </Content>
        <Footer
          style={{
            textAlign: "center",
            backgroundColor: "#001529",
            color: "white",
          }}
        >
          Copyright © Broadpay 2023
        </Footer>
      </Layout>
    </Layout>
  );
};
