import React from 'react';
import { Button, Form, Input, Select } from 'antd';
import UserService from '../../../services/userService';
import authHeader from '../../../services/authHeader';
import { Component } from 'react';
import { API_URL } from '../../../config';
import AuthService from '../../../services/authService';
import jwt_decode from 'jwt-decode';
import './AddAccount.css';

import axios from 'axios';
import { withRouter } from 'react-router-dom';

const layout = {
	labelCol: {
		span: 8,
	},
	wrapperCol: {
		span: 16,
	},
};
const tailLayout = {
	wrapperCol: {
		offset: 8,
		span: 16,
	},
};

class AddAccount extends Component {
	state = {
		account_holder_name: '',
		account_number: '',
		bank_name: '',
		sort_code: '',
		swift_code: '',
		country: '',
		currency: '',
		merchant_id: 0,
		message: '',
		isError: undefined,
		loading: false,
		countries: [],
	};

	onChange = (input) => {
		const { name, value } = input.target;
		this.setState({ [name]: value });
	};

	onCountryselect = (country) => {
		this.setState({ country: country });
	};

	onCurrencySelect = (currency) => {
		this.setState({ currency: currency });
	};

	componentDidMount() {
		const token = AuthService.getCurrentUser();

		const decoded = jwt_decode(token);
		if (decoded.exp < Date.now() / 1000) {
			this.props.history.push({
				pathname: '/',
				errorMessage: 'Kindly sign in again.',
			});
		}
		axios
			.get(`${API_URL}countries`, { headers: authHeader() })
			.then((res) =>
				this.setState({ countries: [...res.data.countries] }),
			)
			.catch((err) => console.log(''));
	}

	onFinish = async () => {
		const response = await UserService.postAddBank(this.state);
		const message = response.data.message;

		this.setState({
			message: response.data.message,
			isError: response.data.isError,
		});
		if (!this.state.isError) {
			this.props.history.push({
				pathname: '/dashboard/bank-accounts',
				message: message,
			});
		}
	};

	onFinishFailed = (errorInfo) => {
		// console.log('Failed:', errorInfo);
		return;
	};
	currency = ['ZMW', 'USD', 'GBP', 'ZAR'];
	render() {
		return (
			<Form
				{...layout}
				name='basic'
				initialValues={{
					remember: true,
				}}
				onFinish={this.onFinish}
				onFinishFailed={this.onFinishFailed}
				className='add-bank-form'>
				{/* {console.log(this.props)} */}
				{this.state.isError ? (
					<p style={{ color: 'red' }} className='text-center'>
						{this.state.message}
					</p>
				) : null}
				<Form.Item
					label='Account Holder Name'
					name='account_holder_name'
					rules={[
						{
							required: true,
							message:
								'Please enter the name of the account holder!',
						},
					]}>
					<Input
						name='account_holder_name'
						onChange={this.onChange}
					/>
				</Form.Item>

				<Form.Item
					label='Account Number'
					name='account_number'
					rules={[
						{
							required: true,
							message: 'Please add account number!',
						},
					]}>
					<Input
						name='account_number'
						type='number'
						onChange={this.onChange}
					/>
				</Form.Item>
				<Form.Item
					label='Bank Name'
					name='bank_name'
					rules={[
						{
							required: true,
							message: 'Please add bank name!',
						},
					]}>
					<Input
						name='bank_name'
						type='text'
						onChange={this.onChange}
					/>
				</Form.Item>
				<Form.Item
					label='Sort Code'
					name='sort_code'
					rules={[
						{
							required: true,
							message: 'Please add Sort Code!',
						},
					]}>
					<Input name='sort_code' onChange={this.onChange} />
				</Form.Item>
				<Form.Item
					label='Swift Code'
					name='swift_code'
					rules={[
						{
							required: true,
							message: 'Please add Swift Code!',
						},
					]}>
					<Input name='swift_code' onChange={this.onChange} />
				</Form.Item>

				<Form.Item
					label='Country'
					name='country'
					rules={[
						{
							required: true,
							message: 'Please choose country!',
						},
					]}>
					<Select name='countries' onSelect={this.onCountryselect}>
						{this.state.countries.map((country) => {
							return (
								<Select.Option value={country.name}>
									{country.name}
								</Select.Option>
							);
						})}
					</Select>
				</Form.Item>

				<Form.Item
					label='Currency'
					name='currency'
					rules={[
						{
							required: true,
							message: 'Please choose currency!',
						},
					]}>
					<Select name='currency' onSelect={this.onCurrencySelect}>
						{this.currency.map((currency) => {
							return (
								<Select.Option value={currency}>
									{currency}
								</Select.Option>
							);
						})}
					</Select>
				</Form.Item>

				<Form.Item>
					<Button
						type='primary'
						htmlType='submit'
						className='save-account-button'>
						Save Account
					</Button>
				</Form.Item>
			</Form>
		);
	}
}

export default withRouter(AddAccount);
