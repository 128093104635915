import axios from 'axios';
import authHeader from './authHeader';
import { API_URL } from '../config';

class UserService {
	async getOverview() {
		return await axios.get(`${API_URL}overview`, { headers: authHeader() });
	}

	async getTransactions(page) {
		!page ? (page = 14) : (page = page);
		return await axios.get( `${API_URL}transactions?per-page=${page}`, {
			headers: authHeader(),
		});
	}

	getTransactionsOnPages(page) {
		return axios.get(`${API_URL}transactions?page=${page}`, {
			headers: authHeader(),
		});
	}

	async getTransaction(id) {
		return await axios.get(`${API_URL}transaction?id=${id}`, {
			headers: authHeader(),
		});
	}

	getSettlements() {
		return axios.get(`${API_URL}settlements`, { headers: authHeader() });
	}
	getCountries() {
		return axios.get(`${API_URL}countries`, {
			headers: authHeader(),
		});
	}
	async getCurrency() {
		return await axios.get(`${API_URL}currency`, { headers: authHeader() });
	}
	getBankAccounts() {
		return axios.get(`${API_URL}bank-accounts`, { headers: authHeader() });
	}
	getMerchant() {
		return axios.get(`${API_URL}merchant`, { headers: authHeader() });
	}

	getPaymentLinks() {
		return axios.get(`${API_URL}links`, { headers: authHeader() });
	}

	postPaymentLinks(newLink) {
		// const { title, descriptin, currency, amount } = newLink;
		return axios.post(`${API_URL}link`, newLink, { headers: authHeader() });
	}
	async getAccount() {
		return axios.get(`${API_URL}merchant`, { headers: authHeader() });
	}

	async updateProfile(newProfile) {
		const {
			name,
			email,
			phone,
			address,
			merchantCountryName,
			accountCurrency,
			description,
			site_url,
			webhookUrl,
		} = newProfile;
		return axios.put(
			`${API_URL}merchant`,
			{
				name,
				email,
				phone,
				address,
				merchantCountryName,
				accountCurrency,
				description,
				site_url,
				webhook_url: webhookUrl,
			},
			{ headers: authHeader() },
		);
	}
	async postFile(formData) {
		await axios.post(`${API_URL}files/upload`, formData, {
			headers: authHeader(),
		});
	}

	async postAddBank(state) {
		const {
			account_holder_name,
			account_number,
			bank_name,
			sort_code,
			swift_code,
			country,
			currency,
			merchant_id,
		} = state;

		return axios.post(
			`${API_URL}bank-account`,
			{
				account_holder_name,
				account_number,
				bank_name,
				sort_code,
				swift_code,
				country,
				currency,
				merchant_id,
			},
			{ headers: authHeader() },
		);
	}

	async postPayout(payload,id) {
		return await axios.post(`${API_URL}mobile/payout/${id}`, payload, {
			headers: authHeader(),
		});
	}

	deletePaymentLink(ref) {
		return axios.delete(API_URL + `link/${ref}`, { headers: authHeader() });
	}

	async getFiles() {
		return await axios.get(`${API_URL}files`, { headers: authHeader() });
	}
}

export default new UserService();
