import React, { createContext, useState } from 'react';

export const TransactionsContext = createContext();

export const TransactionsContextProvider = ({ children }) => {
	const [allTransactions, setAllTransactions] = useState();
	const [rawTransactions, setRawTransactions] = useState();
	const [loading, setLoading] = useState(true);
	return (
		<div>
			<TransactionsContext.Provider
				value={{
					allTransactions,
					setAllTransactions,
					rawTransactions,
					setRawTransactions,
				}}>
				{children}
			</TransactionsContext.Provider>
		</div>
	);
};
