import React, { useState } from 'react';
import { Layout, Menu, Breadcrumb } from 'antd';
import {
	PieChartOutlined,
	UserOutlined,
	RiseOutlined,
	MailOutlined,
	LinkOutlined,
	SettingOutlined,
	ProjectOutlined,
	BankOutlined,
	FileSearchOutlined,
	DollarCircleOutlined,
} from '@ant-design/icons';
import Title from 'antd/lib/typography/Title';
import Avatar from 'antd/lib/avatar/avatar';
import { Dropdown } from 'antd';
import { NavLink, Route } from 'react-router-dom';
import './elements.css';
import { Overview } from '../dashboardPages/Overview/Overview';
import { Transactions } from '../dashboardPages/Transactions/Transactions';
import { Settings } from '../dashboardPages/Settings/Settings';
import { Settlements } from '../dashboardPages/Settlements/Settlements';
import { PaymentLinks } from '../dashboardPages/PaymentLinks/PaymentLinks';
import { Payouts } from '../dashboardPages/Payouts/Payouts';
import { Account } from '../dashboardPages/Account/Account';
import AddAccount from '../dashboardPages/BankAccounts/AddBankAccount';
import BankAccount from '../dashboardPages/BankAccounts/BankAccounts';
import AuthService from '../../services/authService';
import { NotFound } from '../dashboardPages/Errors/NotFound';

const { Header, Content, Footer, Sider } = Layout;

export const SideMenu = () => {
	// eslint-disable-next-line
	const [collapsed, setCollapsed] = useState(false);
	// eslint-disable-next-line
	const onCollapse = (collapsed) => {
		console.log(collapsed);
		setCollapsed({ collapsed });
	};

	//create user menu for logout and account
	const menu = (
		<Menu>
			<Menu.Item>
				{' '}
				<NavLink to='/dashboard/account'>Account</NavLink>{' '}
			</Menu.Item>
			<Menu.Item onClick={AuthService.logout}>Logout</Menu.Item>
		</Menu>
	);
	return (
		<Layout>
			<div>
				<Header
					// className='site-layout-background'
					style={{
						paddingRight: '4px',
						position: 'fixed',
						width: '100%',
						opacity: '1',
						zIndex: '1',
						// overFlow: 'auto',
					}}>
					<Dropdown className='left-nav-items' overlay={menu}>
						<span onClick={(e) => e.preventDefault()}>
							<Avatar
								className='avater'
								size='large'
								icon={<UserOutlined />}
							/>
						</span>
					</Dropdown>
					{/* <Avatar
					overlay={menu}
					size='large'
					style={{ float: 'right' }}
					icon={<UserOutlined />}
				/>{' '} */}
					<Title
						level={3}
						style={{
							paddingTop: '1%',
							color: 'white',
						}}>
						Merchant Portal
					</Title>
				</Header>
			</div>
			<Layout className='site-layout' style={{ paddingTop: '60px' }}>
				<Sider
					collapsible
					breakpoint='lg'
					collapsedWidth='0'
					className='sider'
					width={200}
					style={{
						transition: '.5s ease',
						opacity: '1',
						zIndex: '1',
						position: 'fixed',
						overFlow: 'scroll',
						height: '100vh',
					}}>
					<div className='logo' />
					<Menu
						theme='dark'
						defaultSelectedKeys={['1']}
						mode='inline'
						style={{ marginTop: '10%' }}>
						<Menu.Item key='1' icon={<PieChartOutlined />}>
							<NavLink to='/dashboard/overview'>Overview</NavLink>
						</Menu.Item>
						<Menu.Item key='2' icon={<DollarCircleOutlined />}>
							<NavLink to='/dashboard/transactions'>
								Transactions
							</NavLink>
						</Menu.Item>
						<Menu.Item key='3' icon={<ProjectOutlined />}>
							<NavLink to='/dashboard/settlements'>
								Settlements
							</NavLink>
						</Menu.Item>
						<Menu.Item key='4' icon={<LinkOutlined />}>
							<NavLink to='/dashboard/payment-links'>
								{' '}
								Payment Links
							</NavLink>
						</Menu.Item>
						<Menu.Item key='5' icon={<RiseOutlined />}>
							<NavLink to='/dashboard/payouts'> Payouts</NavLink>
						</Menu.Item>
						<Menu.Item key='6' icon={<BankOutlined />}>
							<NavLink to='/dashboard/bank-accounts'>
								{' '}
								Bank Acount
							</NavLink>
						</Menu.Item>
						<Menu.Item key='7' icon={<FileSearchOutlined />}>
							<a
								href='https://documenter.getpostman.com/view/688123/T17M6k2j'
								target='blank'>
								{' '}
								Documentations
							</a>
						</Menu.Item>
						<Menu.Item key='8' icon={<SettingOutlined />}>
							<NavLink to='/dashboard/settings'>
								{' '}
								Settings
							</NavLink>
						</Menu.Item>
					</Menu>
				</Sider>
				<Layout
				// style={{
				// 	height: '100vh',
				// 	boxSizing: 'border-box',
				// 	backgroundColor: 'white',
				// }}
				>
					<Content
						className='content'
						style={{
							paddingLeft: '15%',
							// marginRight: '2%',
							paddingTop: '1%',
							width: '95%',
							boxSizing: 'border-box',
							zIndex: 0,
							// overflow: 'scroll',
							// position: 'fixed',
							// height: '100vh',
							// backgroundColor: 'green',
						}}>
						{/* <Breadcrumb style={{ margin: '16px 0' }}>
							<Breadcrumb.Item>Portal</Breadcrumb.Item>
							<Breadcrumb.Item>overview</Breadcrumb.Item>
						</Breadcrumb> */}
						<div
							className='site-layout-background'
							style={{ padding: 24, marginBottom: '15%' }}>
							<Route
								path='/dashboard/overview'
								component={Overview}
								exact
							/>
							<Route
								path='/dashboard/transactions'
								component={Transactions}
								exact
							/>
							<Route
								path='/dashboard/payment-links'
								component={PaymentLinks}
								exact
							/>
							<Route
								path='/add-account'
								component={AddAccount}
								exact
							/>
							<Route
								path='/dashboard/payouts'
								component={Payouts}
								exact
							/>
							<Route
								path='/dashboard/settings'
								component={Settings}
								exact
							/>
							<Route
								path='/dashboard/settlements'
								component={Settlements}
								exact
							/>

							<Route
								path='/dashboard/bank-accounts'
								component={BankAccount}
								exact
							/>
							{/* <Route
								path='/dashboard/documentation'
								target="_blank"
								component={() =>
									(
										window.location.href =
										'https://documenter.getpostman.com/view/688123/T17M6k2j')
								}
								exact
							/> */}

							<Route
								path='/dashboard/account'
								component={Account}
								exact
							/>
						</div>
					</Content>
					<Footer
						style={{
							// paddingLeft: '50%',
							height: '20px',
							textAlign: 'center',
						}}>
						Copyright © Broadpay 2023
					</Footer>
				</Layout>
			</Layout>
		</Layout>
	);
};
