import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { Button, Form, Input, Select, Radio } from "antd";
import UserService from "../../../services/userService";

import { Spinner } from "../../elements/Spinner/Spinner";

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};

export const CreateLink = (props) => {
  const history = useHistory();
  const [details, setDetails] = useState({
    title: "",
    description: "",
    currency: "",
    amount: 0,
    charge_me: true,
    redirect_url: "",
    edit: true,
    errorInfo: false,
  });
  const [valid, setValid] = useState(true);
  const [loading, setLoading] = useState(false);

  const onSave = async () => {
    setLoading(true);
    const { title, description, currency, amount, charge_me } = details;
    if (!title || !description || !currency ) {
      setValid(false);
      setLoading(false);
      return;
    }

    const newLink = {
      title,
      description,
      currency,
      charge_me,
      amount,
    };

    await UserService.postPaymentLinks(newLink);
    setLoading(false);
    // window.location.reload();
  };

  const onChange = (input) => {
    const { name, value } = input.target;
    setDetails({ ...details, [name]: value });
  };


  const onFinish = async () => {
    return;
  };

  const onFinishFailed = (errorInfo) => {
    // console.log('Failed:', errorInfo);
    setDetails({ errorInfo: true });
  };

  return (
    <>
      {!loading ? (
        <>
          {valid ? (
            ""
          ) : (
            <bold style={{ color: "red", marginLeft: "35%" }}>
              Fill in all required fields
            </bold>
          )}
          <Form
            {...layout}
            name="basic"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            className="form-account"
          >
            <div>
              <Form.Item
                label="Title"
                name="title"
                rules={[
                  {
                    required: true,
                    message: "Please enter the title!",
                  },
                ]}
                onFinishFailed={onFinishFailed}
              >
                <Input name="title" onChange={onChange} />
              </Form.Item>

              <Form.Item
                label="Description"
                name="description"
                rules={[
                  {
                    required: true,
                    message: "Please add description!",
                  },
                ]}
              >
                <Input name="description" type="text" onChange={onChange} />
              </Form.Item>
              <Form.Item
                label="Currency"
                name="currency"
                rules={[
                  {
                    required: true,
                    message: "Please add currency",
                  },
                ]}
              >
                <Select
                  onChange={(res) =>
                    onChange({
                      target: {
                        name: "currency",
                        value: res,
                      },
                    })
                  }
                  name="currency"
                  options={[
                    { value: "USD", name: "USD" },
                    { value: "ZMW", name: "ZMW" },
                  ]}
                />
              </Form.Item>

              <Form.Item label="Amount" name="amount"
                rules={[
                  {
                    required: false,
                  },
                ]}
              >
                <Input name="amount" onChange={onChange} type="number" />
              </Form.Item>
              <Form.Item label="redirect_url" name="redirect_url"
                rules={[
                  {
                    required: false,
                  },
                ]}
              >
                <Input name="redirect_url" onChange={onChange} />
              </Form.Item>
            </div>
            <Form.Item
              label="Charge"
              name="charge_me"
              rules={[
                {
                  required: false,
                  message: "Please select",
                },
              ]}
            >
              <Radio.Group onChange={onChange} value={details.charge_me} name="charge_me">
                <Radio.Button value={true}>Charge Me</Radio.Button>
                <Radio.Button value={false}>Charge Customer</Radio.Button>
              </Radio.Group>
            </Form.Item>
            <Form.Item className="form-account-edit-save">
              <Button type="primary" onClick={onSave}>
                Create Link
              </Button>
            </Form.Item>
          </Form>
        </>
      ) : (
        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "80vh",
          }}
        >
          <Spinner />
        </div>
      )}
    </>
  );
};
