import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useHistory } from 'react-router';
import { Layout } from 'antd';
// import './LoginForm.css';
import axios from 'axios';
import { API_URL } from '../../../config';
import { Spinner } from '../../elements/Spinner/Spinner';
import { Link, NavLink } from 'react-router-dom';
import { Header, Content, Footer } from 'antd/lib/layout/layout';
import authService from '../../../services/authService';

export const UpdatePassword = (props) => {
	const history = useHistory();
	const url = window.location.href;
	const token = url.toString().split('=')[1];

	const [newPassword, setNewPassword] = useState({
		password: '',
		confirmPassword: '',
	});

	const [loading, setLoading] = useState(false);
	const [message, setMessage] = useState('');
	const [responseMessage, setResponseMessage] = useState(false);

	//Action when submit button is clicked
	const onSubmit = async (event) => {
		event.preventDefault();

		if (newPassword.password !== newPassword.confirmPassword) {
			setResponseMessage('');
			return setMessage('Passwords do not match');
		}
		setLoading(true);
		const response = await authService.postNewPassword(
			newPassword.password,
			token,
		);
		if (response.data.message.includes('Error')) {
			return setResponseMessage('Reset Link expired');
		}
		setResponseMessage(response.data.message);
		setLoading(false);
		return history.push('/');
	};

	//When user types in the input box
	const onchange = (event) => {
		const { name, value } = event.target;

		event.preventDefault();
		setNewPassword((values) => ({
			...values,
			[name]: value,
		}));
	};

	return (
		<Layout style={{ height: '100vh' }}>
			<Header
				className='site-layout-background'
				style={{
					color: 'white',
					textAlign: 'center',
					fontSize: '20px',
					fontWeight: 'bolder',
				}}>
				<NavLink to='/' style={{ color: 'white' }}>
					Broadpay Gateway Portal
				</NavLink>
			</Header>
			<Layout>
				<Content className='content'>
					<form className='login-form'>
						{/* {loading ? (
							<Spinner />
						) : ( */}
						<>
							<div class='form-group'>
								<label for='InputEmail'>New Password</label>
								<input
									// style={{ width: '50%' }}
									name='password'
									type='password'
									class='form-control'
									id='password'
									aria-describedby='emailHelp'
									placeholder='Enter new password'
									onChange={onchange}
								/>
								<small
									id='emailHelp'
									class='form-text text-muted'>
									Never share your password with anyone else
								</small>
							</div>
							<div class='form-group'>
								<label for='InputPassword'>
									Confirm Password
								</label>
								<input
									// style={{ width: '50%' }}
									name='confirmPassword'
									type='password'
									class='form-control'
									id='InputPassword'
									placeholder='Password'
									onChange={onchange}
								/>
							</div>

							<button
								// type='submit'
								class='btn btn-primary btn-block'
								onClick={onSubmit}>
								Submit
							</button>
							<div
								style={{
									display: 'flex',
									flexDirection: 'row',
									justifyContent: 'space-between',
									// marginBottom: '5px',
									// marginRight: '80%',
								}}>
								<button
									type='button'
									class='btn btn-link'
									style={{
										alignSelf: 'center',
									}}>
									<Link to='/' class='badge-light'>
										{' '}
										Sign in
									</Link>
								</button>

								<button
									href='/register'
									type='button'
									class='btn btn-link'>
									<Link to='/register' class='badge-light'>
										{' '}
										Sign up
									</Link>
								</button>
							</div>
							{responseMessage ? (
								<small
									style={
										responseMessage.includes('Invalid')
											? {
													color: 'red',
													paddingLeft: '10%',
											  }
											: {
													color: 'green',
													paddingLeft: '10%',
													width: 'min-content',
											  }
									}>
									{responseMessage}
								</small>
							) : message ? (
								<small
									style={{
										color: 'red',
									}}>
									{message}
								</small>
							) : (
								''
							)}
						</>
						{/* )} */}
					</form>
				</Content>
				<Footer
					style={{
						textAlign: 'center',
						backgroundColor: '#001529',
						color: 'white',
					}}>
					Copyright © Broadpay 2023
				</Footer>
			</Layout>
		</Layout>
	);
};
