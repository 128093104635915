import React, { Component } from 'react';
import UserService from '../../../services/userService';
import { Spinner } from '../../elements/Spinner/Spinner';
import axios from 'axios';

import './BankAccounts.css';
import { Button } from 'antd';
import { AccountTable } from './AccountTable';
import AddAccount from './AddBankAccount';
import { withRouter } from 'react-router-dom';
import authHeader from '../../../services/authHeader';
import { API_URL } from '../../../config';
import AuthService from '../../../services/authService';
import jwt_decode from 'jwt-decode';

class BankAccounts extends Component {
	state = {
		bankAccounts: [],
		isError: false,
		message: '',
		loading: true,
		account_holder_name: '',
		account_number: '',
		bank_name: '',
		sort_code: '',
		swift_code: '',
		country: '',
		currency: '',
		merchant_id: 0,
		add_bank_message: '',
	};
	onChange = (input) => {
		const { name, value } = input.target;
		this.setState({ [name]: value });
	};

	componentDidMount() {
		const token = AuthService.getCurrentUser();
		const decoded = jwt_decode(token);
		if (decoded.exp < Date.now() / 1000) {
			this.props.history.push({
				pathname: '/',
				errorMessage: 'Kindly sign in again.',
			});
		}

		if (this.props.history.location.message) {
			this.setState({
				add_bank_message: this.props.history.location.message,
			});
		}

		UserService.getBankAccounts()
			.then((res) =>
				this.setState(
					{
						bankAccounts: [...res.data.bankAccounts],
						isError: res.data.isError,
						message: res.data.message,
						loading: false,
					},
					// () => console.log(this.state),
				),
			)
			.catch((error) => console.log(error.message));
	}

	onAddAccount = () => {
		return this.props.history.push({
			pathname: '/add-account',
		});
	};

	render() {
		return (
			<div className='bankaccounts-page'>
				{this.state.loading ? (
					<div className='Spinner'>
						<Spinner />
					</div>
				) : this.state.bankAccounts.length === 0 ? (
					<>
						<h4 style={{ marginTop: '10%', marginLeft: '35%' }}>
							you have not added any bank account yet
						</h4>
						{/* <b>You have not added any bank account yet</b> */}
						<Button
							type='primary'
							onClick={this.onAddAccount}
							style={{ marginLeft: '41%' }}>
							Add Account
						</Button>
					</>
				) : (
					<>
						{this.state.add_bank_message ? (
							<p
								style={{ color: 'green' }}
								className='text-center'>
								{this.state.add_bank_message}
							</p>
						) : null}
						<AccountTable bankAccounts={this.state.bankAccounts} />
						<Button type='primary' onClick={this.onAddAccount}>
							Add Account
						</Button>
					</>
				)}
			</div>
		);
	}
}

export default withRouter(BankAccounts);
