import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { Layout, Result, Button } from 'antd';
import axios from 'axios';
import { API_URL } from '../../../config';
import { Spinner } from '../../elements/Spinner/Spinner';
import { Link, NavLink } from 'react-router-dom';
import { Content, Footer, Header } from 'antd/lib/layout/layout';
import { Layers } from '@material-ui/icons';

export const EmailVerify = () => {
	const history = useHistory();
	const url = window.location.href;
	const token = url.toString().split('=')[1];
	const [isVerified, setIsVerified] = useState(false);

	useEffect(async () => {
		const res = await axios.post(
			`dashboard.broadpay.io/api/v1/verify?token=${token}`
		);
		if (!res.data.isError) {
			return setIsVerified(true);
		}
		setIsVerified(false);
	}, []);

	return (
		<Layout style={{ height: '100vh' }}>
			<Header
				className='site-layout-background'
				style={{
					color: 'white',
					textAlign: 'center',
					fontSize: '20px',
					fontWeight: 'bolder',
				}}>
				<NavLink to='/' style={{ color: 'white' }}>
					Broadpay Gateway Portal
				</NavLink>
			</Header>
			<Layout>
				<Content className='content'>
					{isVerified ? (
						<Result
							status='success'
							title='Congratulations you have successfully confirmed your email!'
							extra={[
								<Button key='login'>
									<Link to='/'> Go login</Link>
								</Button>,
							]}
						/>
					) : (
						<Result
							status='warning'
							title='There were some problems verifying your email.'
							extra={
								<Button type='primary' key='console'>
									<Link to='/'> Go login</Link>
								</Button>
							}
						/>
					)}
				</Content>
				<Footer
					style={{
						textAlign: 'center',
						backgroundColor: '#001529',
						color: 'white',
					}}>
					Copyright © Broadpay 2023
				</Footer>
			</Layout>
		</Layout>
	);
};
